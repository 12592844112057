import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import type { Website } from 'types/graphql'

import PageFrame, {
  CodeSection,
  FrameElementEvent,
  PageFrameHandler,
  PageWithCodeSections,
} from '../PageFrame/PageFrame'

type WebsitePanelProps = {
  website: Website
  activePage: PageWithCodeSections
  websiteWidth: 'mobile' | 'tablet' | 'laptop' | 'desktop'
  onFrameElementClick: (event: FrameElementEvent) => void
  onAddNewCodeSectionAbove: (id: string) => void
  onAddNewCodeSectionBelow: (id: string) => void
  onMoveClickedCodeSectionUp: (id: string) => void
  onMoveClickedCodeSectionDown: (id: string) => void
  onDeleteClickedCodeSection: (id: string) => void
}

export type WebsitePanelHandler = {
  getUpdatedPage: () => {
    updatedPage: PageWithCodeSections
    headerCodeSection: CodeSection
    footerCodeSection: CodeSection
  }
  clearClickedElement: () => void
}

const WebsitePanel = forwardRef(
  (
    {
      website,
      activePage,
      websiteWidth,
      onFrameElementClick,
      onAddNewCodeSectionAbove,
      onAddNewCodeSectionBelow,
      onMoveClickedCodeSectionUp,
      onMoveClickedCodeSectionDown,
      onDeleteClickedCodeSection,
    }: WebsitePanelProps,
    ref
  ) => {
    // give the parent a hook into the panel
    useImperativeHandle<unknown, WebsitePanelHandler>(ref, () => {
      return {
        getUpdatedPage: getUpdatedHtml,
        clearClickedElement: clearClickedElement,
      }
    })

    const pageFrameRef = useRef<PageFrameHandler>(null)
    const pageFrameParentRef = useRef<HTMLDivElement>(null)

    const initialWidth =
      websiteWidth === 'desktop'
        ? '1920px'
        : websiteWidth === 'laptop'
        ? '1280px'
        : websiteWidth === 'tablet'
        ? '768px'
        : '375px'

    const [websiteWidthPx, setWebsiteWidthPx] = useState(initialWidth)
    const [websiteScale, setWebsiteScale] = useState(1)
    const [websiteOrigin, setWebsiteOrigin] = useState('origin-top')
    const [offsetLeft, setOffsetLeft] = useState(0)

    useEffect(() => {
      changeViewportWidth(websiteWidth)
    }, [websiteWidth])

    //running this every time websiteWidth changes because it creates
    //a closure around the websiteWidth
    useEffect(() => {
      window.addEventListener('resize', handleBrowserResize)

      // Clean up event listener on component unmount
      return () => {
        window.removeEventListener('resize', handleBrowserResize)
      }
    }, [websiteWidth])

    const handleBrowserResize = () => {
      changeViewportWidth(websiteWidth)
    }

    const adjustHeight = () => {
      if (pageFrameRef.current) {
        pageFrameRef.current.adjustHeight()
      }
    }

    const getUpdatedHtml = () => {
      return pageFrameRef.current ? pageFrameRef.current.getUpdatedPage() : null
    }

    const clearClickedElement = () => {
      if (pageFrameRef.current) {
        console.log('clearing clicked element in website panel')
        pageFrameRef.current.clearClickedElement()
      }
    }

    const onElementClick = (event: FrameElementEvent) => {
      onFrameElementClick(event)
    }

    const changeViewportWidth = (
      size: 'mobile' | 'tablet' | 'laptop' | 'desktop'
    ) => {
      const widthMap = {
        mobile: 375,
        tablet: 768,
        laptop: 1280,
        desktop: 1920,
      }

      const setViewport = (width: number) => {
        setWebsiteWidthPx(`${width}px`)
        adjustHeight()
        setOffsetLeft(0)

        if (pageFrameParentRef.current.offsetWidth < width) {
          setWebsiteScale(pageFrameParentRef.current.offsetWidth / width)
          setWebsiteOrigin('origin-top-left')
        } else {
          setWebsiteScale(1)
          setOffsetLeft((pageFrameParentRef.current.offsetWidth - width) / 2)
        }
      }

      setViewport(widthMap[size])
    }

    return (
      <div
        ref={pageFrameParentRef}
        className="relative h-full w-full overflow-x-hidden overflow-y-scroll"
      >
        <PageFrame
          ref={pageFrameRef}
          className={`absolute left-[${offsetLeft}px] top-0 w-[${websiteWidthPx}] scale-[${websiteScale}] ${websiteOrigin} h-full bg-white`}
          page={activePage}
          headerCodeSection={website.headerCodeSection as CodeSection}
          footerCodeSection={website.footerCodeSection as CodeSection}
          colorPalette={website.colorPalette}
          onElementClick={onElementClick}
          onAddNewCodeSectionAbove={onAddNewCodeSectionAbove}
          onAddNewCodeSectionBelow={onAddNewCodeSectionBelow}
          onMoveClickedCodeSectionUp={onMoveClickedCodeSectionUp}
          onMoveClickedCodeSectionDown={onMoveClickedCodeSectionDown}
          onDeleteClickedCodeSection={onDeleteClickedCodeSection}
          isNewChatPageFrame={true}
          // onElementHover={onFrameElementHover}
          // onPageChange={onPageChange}
        ></PageFrame>
      </div>
    )
  }
)

export default WebsitePanel
