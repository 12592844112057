import React, { Fragment, useState } from 'react'

import { Dialog, Listbox, Transition } from '@headlessui/react'
import type { Website } from 'types/graphql'

import { useQuery, useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'

const SAVE_WEBSITE = gql`
  mutation SaveWebsiteSettingsMutation(
    $id: String!
    $input: UpdateWebsiteInput!
  ) {
    updateWebsite(id: $id, input: $input) {
      __typename
      id
      googleAnalyticsId
      scriptsInHtml
      contentInHead
      contactFormEmail
      agencyShortCode
    }
  }
`

const SAVE_WEBSITE_CUSTOM_DOMAIN = gql`
  mutation SaveWebsiteCustomDomainMutation(
    $id: String!
    $customDomain: String
  ) {
    updateWebsiteCustomDomain(id: $id, customDomain: $customDomain) {
      __typename
      id
      customDomain
      verifiedDomain
      cloudflareZoneId
      cloudflareZoneActivatedOn
      cloudflareOriginalDNSHost
      cloudflareOriginalNameservers
      cloudflareOriginalRegistrar
      cloudflareNameservers
    }
  }
`

const SAVE_PAGE = gql`
  mutation SavePageSettingsMutation($id: String!, $input: UpdatePageInput!) {
    updatePage(id: $id, input: $input) {
      __typename
      id
      seoTitle
      seoDescription
      seoMetaTags
    }
  }
`

const SET_PLAN_AND_GET_STRIPE_CHECKOUT_REDIRECT_URL = gql`
  mutation SetPlanAndGetStripeCheckoutRedirectUrlForSettingsMutation(
    $id: String!
    $plan: String!
    $promoCode: String
  ) {
    setPlanAndGetStripeCheckoutRedirectUrlForSettings(
      id: $id
      plan: $plan
      promoCode: $promoCode
    )
  }
`

const CHECK_NAMESERVERS_MUTATION = gql`
  mutation CheckNameserversMutation($id: String!) {
    checkNameservers(id: $id) {
      __typename
      id
    }
  }
`

const DELETE_WEBSITE_MUTATION = gql`
  mutation DeleteWebsiteMutation($id: String!) {
    deleteWebsite(id: $id) {
      __typename
      id
    }
  }
`

type SettingsPanelProps = {
  website: Website
}

const SettingsPanel = ({ website: websiteProp }: SettingsPanelProps) => {
  const [website, setWebsite] = useState<Website>(websiteProp)
  const { isAuthenticated, logIn, signUp } = useAuth()

  const [googleAnalyticsId, setGoogleAnalyticsId] = useState(
    website?.googleAnalyticsId
  )

  const [contactFormEmail, setContactFormEmail] = useState(
    website?.contactFormEmail
  )

  const [currentPlan, setCurrentPlan] = useState(website?.stripePlan)
  const [isSavingPlan, setIsSavingPlan] = useState(null)

  const homepage = website?.Pages.find((page) => page.isHomepage)

  const [currentWebsite, setCurrentWebsite] = useState(website)
  const [currentPage, setCurrentPage] = useState(homepage)

  const [seoTitle, setSeoTitle] = useState(homepage?.seoTitle)
  const [seoDescription, setSeoDescription] = useState(homepage?.seoDescription)
  const [seoMetaTags, setSeoMetaTags] = useState(homepage?.seoMetaTags)
  const [scriptsInHtml, setScriptsInHtml] = useState(website?.scriptsInHtml)
  const [contentInHead, setContentInHead] = useState(website?.contentInHead)
  const [agencyShortCode, setAgencyShortCode] = useState(
    website?.agencyShortCode
  )
  const [customDomain, setCustomDomain] = useState(website?.customDomain || '')
  const [isSaving, setIsSaving] = useState(false)
  const [isCheckingNameservers, setIsCheckingNameservers] = useState(false)
  const [domainErrorString, setDomainErrorString] = useState(null)
  const [addingCustomDomain, setAddingCustomDomain] = useState(false)
  const [domainStatusString, setDomainStatusString] = useState(null)
  const [pricingData, setPricingData] = useState([
    { name: 'Free', price: 0, currencyCode: 'USD' },
    { name: 'Pro', price: 10, currencyCode: 'USD' },
  ])
  const [isShowingEmailVerificationModal, setIsShowingEmailVerificationModal] =
    useState(false)
  const [isDeletingWebsite, setIsDeletingWebsite] = useState(false)
  const [deleteErrorString, setDeleteErrorString] = useState(null)

  const [saveWebsite] = useMutation(SAVE_WEBSITE)
  const [saveWebsiteCustomDomain] = useMutation(SAVE_WEBSITE_CUSTOM_DOMAIN)
  const [savePage] = useMutation(SAVE_PAGE)
  const [setPlanAndGetStripeCheckoutRedirectUrlForSettings] = useMutation(
    SET_PLAN_AND_GET_STRIPE_CHECKOUT_REDIRECT_URL
  )
  const [checkNameserversMutation] = useMutation(CHECK_NAMESERVERS_MUTATION)
  const [deleteWebsiteMutation] = useMutation(DELETE_WEBSITE_MUTATION)

  const onSettingsFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {}
  const changeCurrentPage = (page: any) => {}
  const onClickLogin = () => {}
  const onClickSignup = () => {}
  const onClickClaimWebsite = () => {}
  const selectPlan = (plan: string) => {}
  const getFormattedPrice = (plan: string) => {}
  const getUSDPrice = (plan: string) => {}

  const tabs = [
    {
      name: 'General',
      href: `/website/${website?.id}/settings`,
      current: true,
    },
    {
      name: 'Plans',
      href: `/website/${website?.id}/plans`,
      current: false,
    },
    {
      name: 'Custom Domain',
      href: `/website/${website?.id}/custom-domain`,
      current: false,
    },
    {
      name: 'Waitlist Signups',
      href: `/website/${website?.id}/waitlist`,
      current: false,
    },
    {
      name: 'Delete Website',
      href: `/website/${website?.id}/delete-website`,
      current: false,
    },
  ]

  return (
    <>
      <div className="container mx-auto overflow-scroll bg-white px-4 pt-12">
        <h1 className="mb-6 flex flex-col items-center space-y-3 text-3xl font-bold sm:flex-row sm:space-x-3 sm:space-y-0">
          <span>{website?.name}</span>
        </h1>
        <div className="flex flex-row">
          {tabs.map((tab) => {
            return (
              <a
                key={tab?.name}
                href={tab.href}
                className={
                  (tab.current
                    ? 'border-2 border-white border-b-gray-950 text-gray-800'
                    : '') + ' mx-3 py-3 hover:text-gray-800 font-semibold'
                }
              >
                {tab?.name}
              </a>
            )
          })}
        </div>
        <div className="border border-gray-300">
          {tabs[0].current && (
            <form className="w-full max-w-5xl" onSubmit={onSettingsFormSubmit}>
              <div className="bg-white p-6">
                {website?.user && website?.user.agencyOwnerName && (
                  <div className="mb-6">
                    <label
                      htmlFor="agencyShortCode"
                      className="mb-2 block text-sm font-bold text-gray-700"
                    >
                      Agency Website Subdomain:
                    </label>
                    <input
                      type="text"
                      id="agencyShortCode"
                      name="agencyShortCode"
                      className="mb-2 w-[450px] rounded border px-3 py-2 text-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-lime-400"
                      placeholder="<subdomain>.youragency.com"
                      value={agencyShortCode}
                      onChange={(event) => {
                        setAgencyShortCode(event.currentTarget.value.trim())
                      }}
                    />
                    {agencyShortCode && (
                      <div className="text-sm text-gray-400">
                        Access this website at{' '}
                        <a
                          href={`https://${agencyShortCode}.${website.user.agencyOwnerName}`}
                          className="text-blue-500 hover:underline"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {agencyShortCode}.{website.user.agencyOwnerName}
                        </a>
                      </div>
                    )}
                  </div>
                )}
                <div className="mb-6">
                  <label
                    htmlFor="ga_id"
                    className="mb-2 block text-sm font-bold text-gray-700"
                  >
                    Google Analytics ID:
                  </label>
                  <input
                    type="text"
                    id="ga_id"
                    name="ga_id"
                    className="w-[200px] rounded border px-3 py-2 text-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-lime-400"
                    placeholder="GA-XXXXXXXXXX"
                    value={googleAnalyticsId}
                    onChange={(event) => {
                      setGoogleAnalyticsId(event.currentTarget.value.trim())
                    }}
                  />
                </div>
                <div className="mb-6">
                  <label
                    htmlFor="contentInHead"
                    className="mb-2 block text-sm font-bold text-gray-700"
                  >
                    Content in &lt;head&gt;:
                  </label>
                  <textarea
                    id="contentInHead"
                    name="contentInHead"
                    rows={4}
                    className="w-full rounded border px-3 py-2 text-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-lime-400"
                    value={contentInHead}
                    onChange={(event) => {
                      setContentInHead(event.currentTarget.value)
                    }}
                  ></textarea>
                  <div className="text-sm text-gray-400">
                    For script tags, embeds, fonts, favicons, or other content
                    you want placed in the &lt;head&gt; of your website.
                  </div>
                </div>
                <div className="mb-6">
                  <label
                    htmlFor="customScripts"
                    className="mb-2 block text-sm font-bold text-gray-700"
                  >
                    Custom Scripts:
                  </label>
                  <textarea
                    id="customScripts"
                    name="customScripts"
                    rows={4}
                    className="w-full rounded border px-3 py-2 text-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-lime-400"
                    value={scriptsInHtml}
                    onChange={(event) => {
                      setScriptsInHtml(event.currentTarget.value)
                    }}
                  ></textarea>
                  <div className="text-sm text-gray-400">
                    For custom Javascript that will be inserted at the end of
                    the &lt;body&gt; of your website.
                  </div>
                </div>
                <div className="mb-6">
                  <label
                    htmlFor="contactFormEmail"
                    className="mb-2 block text-sm font-bold text-gray-700"
                  >
                    Contact Form Email Override:
                  </label>
                  <input
                    type="text"
                    id="contactFormEmail"
                    name="contactFormEmail"
                    className="mb-2 w-[400px] rounded border px-3 py-2 text-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-lime-400"
                    placeholder="someone@email.com"
                    value={contactFormEmail}
                    onChange={(event) => {
                      setContactFormEmail(event.currentTarget.value.trim())
                    }}
                  />
                  <div className="text-sm text-gray-400">
                    Leave this blank and we'll send any contact form submissions
                    to the email on your user account.
                  </div>
                </div>
                <div className="my-10 max-w-[150px]">
                  <Listbox value={currentPage} onChange={changeCurrentPage}>
                    <div className="relative flex-grow">
                      <Listbox.Button className="sxrelative h-full w-full rounded bg-lime-400 py-2 pl-3 pr-10 text-left font-medium text-black focus:outline-none focus-visible:border-lime-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-lime-300">
                        <span className="block truncate">
                          {currentPage?.name}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <i
                            className="fa-solid fa-chevron-down ml-2"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </Listbox.Button>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute mt-1 max-h-60 w-[200px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {website?.Pages.map((page) => (
                            <Listbox.Option
                              key={page.id}
                              value={page}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                  active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-900'
                                }`
                              }
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block truncate ${
                                      selected ? 'font-medium' : 'font-normal'
                                    }`}
                                  >
                                    {page.name}
                                  </span>
                                  {selected ? (
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-lime-400">
                                      <i
                                        className="fa-solid fa-check"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                </div>
                <div className="mb-6">
                  <label
                    htmlFor="seo_title"
                    className="mb-2 block text-sm font-bold text-gray-700"
                  >
                    SEO Title:
                  </label>
                  <input
                    type="text"
                    id="seo_title"
                    name="seo_title"
                    className="w-full rounded border px-3 py-2 text-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-lime-400"
                    value={seoTitle}
                    onChange={(event) => {
                      setSeoTitle(event.currentTarget.value)
                    }}
                  />
                </div>
                <div className="mb-6">
                  <label
                    htmlFor="seo_description"
                    className="mb-2 block text-sm font-bold text-gray-700"
                  >
                    SEO Description:
                  </label>
                  <textarea
                    id="seo_description"
                    name="seo_description"
                    rows={3}
                    className="w-full rounded border px-3 py-2 text-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-lime-400"
                    value={seoDescription}
                    onChange={(event) => {
                      setSeoDescription(event.currentTarget.value)
                    }}
                  ></textarea>
                </div>
                <div className="mb-6">
                  <label
                    htmlFor="meta_tags"
                    className="mb-2 block text-sm font-bold text-gray-700"
                  >
                    Meta Tags:
                  </label>
                  <textarea
                    id="meta_tags"
                    name="meta_tags"
                    rows={8}
                    className="w-full rounded border px-3 py-2 text-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-lime-400"
                    value={seoMetaTags}
                    onChange={(event) => {
                      setSeoMetaTags(event.currentTarget.value)
                    }}
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="rounded bg-lime-400 px-8 py-4 text-2xl text-black hover:bg-lime-500"
                >
                  {!isSaving && <span>Save</span>}
                  {isSaving && <span>Saving...</span>}
                </button>
                <span className="ml-4 text-gray-500">
                  You must publish your website for these changes to be deployed
                  to your website
                </span>
              </div>
            </form>
          )}

          {tabs[1].current && (
            <div className="sm:p-6">
              <section className="code-section full-pricing-section container mx-auto px-2 py-12 font-['Poppins'] sm:px-6">
                {!isAuthenticated && (
                  <div className="mt-12 flex flex-col items-center justify-center">
                    <h2 className="mb-12 text-xl font-bold">
                      You must log in to change your plan
                    </h2>
                    <ul className="flex items-center gap-x-8 text-white">
                      <li>
                        <a
                          className="rounded bg-sky-500 px-4 py-2 hover:bg-sky-600"
                          href="/login"
                          onClick={onClickLogin}
                        >
                          Log In
                        </a>
                      </li>
                      <li>
                        <a
                          className="rounded bg-sky-500 px-4 py-2 hover:bg-sky-600"
                          href="/signup"
                          onClick={onClickSignup}
                        >
                          Sign Up
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
                {isAuthenticated && !website.user && (
                  <div className="mt-12 flex flex-col items-center justify-center">
                    <h2 className="mb-12 text-xl font-bold">
                      This website has not been saved to your account yet, so
                      billing cannot be modified until claimed.
                    </h2>
                    <button
                      type="button"
                      className="inline-flex items-center rounded bg-lime-400 p-2 font-medium text-gray-800 hover:bg-lime-500 disabled:bg-gray-500 disabled:hover:bg-gray-500"
                      onClick={onClickClaimWebsite}
                    >
                      Claim to my account
                    </button>
                  </div>
                )}
                {isAuthenticated && website.user && (
                  <>
                    <h2
                      id="pricing-header"
                      className="mb-4 text-center text-3xl font-bold sm:px-40 sm:text-3xl sm:leading-[72px]"
                    >
                      Choose The Plan For {website?.name}
                    </h2>
                    <div className="flex flex-col items-center justify-center lg:flex-row">
                      <div
                        id="pricing-plan-1"
                        className={
                          (currentPlan === 'Free'
                            ? 'border-2 border-black bg-lime-400 text-black'
                            : 'text-lime-400') +
                          ' primary-color-bg relative my-6 max-w-lg flex-1 rounded-3xl px-4 py-12 text-center shadow-lg sm:mx-6 sm:px-10'
                        }
                      >
                        {currentPlan === 'Free' && (
                          <div
                            id="pricing-plan-1-most-popular"
                            className="absolute -top-6 left-1/4 mb-2 flex h-[54px] w-1/2 items-center justify-center rounded bg-black px-2 py-1 text-center text-xl font-semibold uppercase text-white"
                          >
                            Current Plan
                          </div>
                        )}
                        <h4
                          id="pricing-plan-1-title"
                          className="mb-4 text-4xl font-semibold"
                        >
                          Free
                        </h4>
                        <p
                          id="pricing-plan-1-text"
                          className="mb-8 font-light text-gray-700 xl:text-xl"
                        >
                          Ideal for personal projects and small businesses
                        </p>
                        <p
                          id="pricing-plan-1-price"
                          className="primary-color-text mb-2 text-6xl font-bold"
                        >
                          {getFormattedPrice('Free')}
                        </p>
                        {getUSDPrice('Free') && (
                          <p
                            id="pricing-plan-1-usd-price"
                            className="mb-2 text-2xl font-bold text-gray-700"
                          >
                            ({getUSDPrice('Free')} USD)
                          </p>
                        )}
                        <p
                          id="pricing-plan-1-pricing-subtext"
                          className="mb-9 text-base font-light uppercase text-gray-700"
                        >
                          PER MONTH PER SITE
                        </p>
                        <button
                          id="pricing-plan-1-button"
                          className="mx-auto mb-11 block w-2/3 rounded border border-gray-800 bg-white py-4 font-semibold uppercase text-gray-800 hover:bg-black hover:text-lime-400"
                          onClick={() => selectPlan('Free')}
                        >
                          {isSavingPlan === 'Free' && (
                            <i className="fa-regular fa-spinner-third animate-spin"></i>
                          )}
                          {currentPlan !== 'Free' &&
                            isSavingPlan !== 'Free' && (
                              <span>Select This Plan</span>
                            )}
                          {currentPlan === 'Free' && <span>Current Plan</span>}
                        </button>
                        <ul
                          id="pricing-plan-1-features"
                          className="space-y-3 font-light"
                        >
                          <li className="flex min-h-[28px] items-center">
                            <i className="fa-regular fa-circle-check fa-xl primary-color-text ml-1 mr-4"></i>
                            <span
                              id="pricing-plan-1-features-1"
                              className="text-left text-lg text-black"
                            >
                              Home Page Generation &amp; Editing
                            </span>
                          </li>
                          <li className="flex min-h-[28px] items-center">
                            <i className="fa-regular fa-circle-check fa-xl primary-color-text ml-1 mr-4"></i>
                            <span
                              id="pricing-plan-1-features-2"
                              className="text-left text-lg text-black"
                            >
                              3M+ Unsplash Photos
                            </span>
                          </li>
                          <li className="flex min-h-[28px] items-center">
                            <i className="fa-regular fa-circle-check fa-xl primary-color-text ml-1 mr-4"></i>
                            <span
                              id="pricing-plan-1-features-3"
                              className="text-left text-lg text-black"
                            >
                              Free Hosting
                            </span>
                          </li>
                          <li className="flex min-h-[28px] items-center">
                            <i className="fa-regular fa-circle-check fa-xl primary-color-text ml-1 mr-4"></i>
                            <span
                              id="pricing-plan-1-features-4"
                              className="text-left text-lg text-black"
                            >
                              Secure HTTPS URLs
                            </span>
                          </li>
                          <li className="flex min-h-[28px] items-center">
                            <i className="fa-regular fa-circle-check fa-xl primary-color-text ml-1 mr-4"></i>
                            <span
                              id="pricing-plan-1-features-5"
                              className="text-left text-lg text-black"
                            >
                              Built-In SEO
                            </span>
                          </li>
                          <li className="flex min-h-[28px] items-center">
                            <i className="fa-regular fa-circle-check fa-xl primary-color-text ml-1 mr-4"></i>
                            <span
                              id="pricing-plan-1-features-5"
                              className="text-left text-lg text-black"
                            >
                              Traffic Analytics
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div
                        id="pricing-plan-2"
                        className={
                          (currentPlan === 'Pro'
                            ? 'border-2 border-black bg-lime-400 text-black'
                            : 'text-lime-400') +
                          ' primary-color-bg relative my-6 max-w-lg flex-1 rounded-3xl px-4 py-12 text-center shadow-lg sm:mx-6 sm:px-10'
                        }
                      >
                        {currentPlan === 'Pro' && (
                          <div
                            id="pricing-plan-2-most-popular"
                            className="absolute -top-6 left-1/4 mb-2 flex h-[54px] w-1/2 items-center justify-center rounded bg-black px-2 py-1 text-center text-xl font-semibold uppercase text-white"
                          >
                            Current Plan
                          </div>
                        )}
                        <h4
                          id="pricing-plan-2-title"
                          className="mb-4 text-4xl font-semibold"
                        >
                          Pro
                        </h4>
                        <p
                          id="pricing-plan-2-text"
                          className="mb-8 font-light text-gray-900 xl:text-xl"
                        >
                          Perfect for growing businesses &amp; professionals
                        </p>
                        <p
                          id="pricing-plan-2-price"
                          className="mb-3 text-6xl font-bold"
                        >
                          {getFormattedPrice('Pro')}
                        </p>
                        {getUSDPrice('Pro') && (
                          <p
                            id="pricing-plan-2-usd-price"
                            className="mb-2 text-2xl font-bold text-gray-700"
                          >
                            ({getUSDPrice('Pro')} USD)
                          </p>
                        )}
                        <p
                          id="pricing-plan-2-pricing-subtext"
                          className="mb-9 text-base font-light uppercase text-gray-900"
                        >
                          PER MONTH PER SITE
                        </p>
                        <button
                          id="pricing-plan-2-button"
                          className="mx-auto mb-11 block w-2/3 rounded border border-black bg-white py-4 font-semibold uppercase text-gray-800 hover:bg-black hover:text-lime-400"
                          onClick={() => selectPlan('Pro')}
                        >
                          {isSavingPlan === 'Pro' && (
                            <i className="fa-regular fa-spinner-third animate-spin"></i>
                          )}
                          {currentPlan !== 'Pro' && isSavingPlan !== 'Pro' && (
                            <span>Select This Plan</span>
                          )}
                          {currentPlan === 'Pro' && <span>Current Plan</span>}
                        </button>
                        <ul
                          id="pricing-plan-2-features"
                          className="space-y-3 font-light"
                        >
                          <li className="flex min-h-[28px] items-center">
                            <i className="fa-regular fa-circle-check fa-xl ml-1 mr-4"></i>
                            <span
                              id="pricing-plan-2-features-1"
                              className="text-left text-lg text-black"
                            >
                              Includes Free Plan Features
                            </span>
                          </li>
                          <li className="flex min-h-[28px] items-center">
                            <i className="fa-regular fa-circle-check fa-xl ml-1 mr-4"></i>
                            <span
                              id="pricing-plan-2-features-2"
                              className="text-left text-lg text-black"
                            >
                              Unlimited Pages
                            </span>
                          </li>
                          <li className="flex min-h-[28px] items-center">
                            <i className="fa-regular fa-circle-check fa-xl ml-1 mr-4"></i>
                            <span
                              id="pricing-plan-2-features-3"
                              className="text-left text-lg text-black"
                            >
                              11M+ iStock Videos
                            </span>
                          </li>
                          <li className="flex min-h-[28px] items-center">
                            <i className="fa-regular fa-circle-check fa-xl ml-1 mr-4"></i>
                            <span
                              id="pricing-plan-2-features-4"
                              className="text-left text-lg text-black"
                            >
                              148M+ iStock Photos
                            </span>
                          </li>
                          <li className="flex min-h-[28px] items-center">
                            <i className="fa-regular fa-circle-check fa-xl ml-1 mr-4"></i>
                            <span
                              id="pricing-plan-2-features-5"
                              className="text-left text-lg text-black"
                            >
                              Custom Domain
                            </span>
                          </li>
                          <li className="flex min-h-[28px] items-center">
                            <i className="fa-regular fa-circle-check fa-xl ml-1 mr-4"></i>
                            <span
                              id="pricing-plan-2-features-7"
                              className="text-left text-lg text-black"
                            >
                              No Landingsite.ai Banner
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                )}
              </section>
            </div>
          )}

          {tabs[2].current && (
            <div className="mt-8 flex flex-col px-6">
              <h1 className="mb-6 text-center text-xl font-bold sm:text-left">
                Custom Domain Settings
              </h1>
              {currentWebsite.stripePlan === 'Free' && (
                <div className="mb-6 text-sm text-red-500">
                  <span>Custom domains are only available on paid plans</span>
                  <a
                    href={`/website/${currentWebsite.id}/plans`}
                    className="ml-2 text-blue-500 hover:underline"
                  >
                    Upgrade Now
                  </a>
                </div>
              )}
              {currentWebsite.stripePlan !== 'Free' &&
                !currentWebsite.customDomain && (
                  <>
                    <div className="mb-6 text-sm text-red-500">
                      <div>
                        You must purchase your domain before adding it here.
                      </div>
                    </div>
                    <div className="mb-6 text-sm">
                      Need to buy a domain?{' '}
                      <a
                        href={`/website/${website.id}?nextSteps=true`}
                        className="text-blue-500 underline"
                      >
                        Click here
                      </a>
                    </div>
                  </>
                )}
              {currentWebsite.stripePlan !== 'Free' && (
                <div className="mb-6 text-sm text-red-500">
                  <div>
                    If you have any questions please email
                    support@landingsite.ai
                  </div>
                </div>
              )}
              <div className="w-full max-w-2xl">
                <div className="bg-white sm:p-6">
                  {(!currentWebsite.customDomain || addingCustomDomain) && (
                    <>
                      <div className="mb-24 flex flex-col sm:block">
                        <label
                          htmlFor="custom_domain"
                          className="mb-2 block text-sm font-bold text-gray-700"
                        >
                          Custom Domain:
                        </label>
                        <input
                          type="text"
                          id="custom_domain"
                          name="custom_domain"
                          className="w-full rounded border px-3 py-2 text-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-sky-400 sm:w-[400px]"
                          placeholder="yourdomain.com"
                          value={customDomain}
                          onChange={(event) => {
                            setCustomDomain(event.currentTarget.value)
                          }}
                        />
                        <button
                          type="button"
                          disabled={currentWebsite.stripePlan === 'Free'}
                          className="rounded bg-sky-500 px-4 py-2 font-semibold text-white hover:bg-sky-600 disabled:bg-gray-400 disabled:hover:bg-gray-400"
                          onClick={onClickCustomDomainAdd}
                        >
                          {!addingCustomDomain && <span>Add</span>}
                          {addingCustomDomain && (
                            <span>
                              <i className="fa-regular fa-spinner-third mr-2 animate-spin"></i>
                              Adding...
                            </span>
                          )}
                        </button>
                        <div className="mt-2 text-sm">{domainStatusString}</div>
                        <div className="mt-2 text-sm text-red-500">
                          {domainErrorString}
                        </div>
                      </div>
                    </>
                  )}
                  {currentWebsite.customDomain && !addingCustomDomain && (
                    <>
                      <div className="relative mb-12">
                        <div className="mb-8 text-lg font-semibold">
                          <span>{currentWebsite.customDomain}</span>
                          {currentWebsite.customDomain ===
                            currentWebsite.verifiedDomain && (
                            <span className="ml-2 text-sm text-green-500">
                              <span>Verified</span>
                              <i className="fa-regular fa-circle-check ml-1"></i>
                            </span>
                          )}
                          {currentWebsite.customDomain !==
                            currentWebsite.verifiedDomain && (
                            <span className="ml-2 text-sm text-yellow-500">
                              <span>Not Verified</span>
                            </span>
                          )}
                          <button
                            className="rounded-lg border border-gray-300 px-2 py-1 text-sm hover:border-red-600 hover:bg-red-500 hover:text-white sm:absolute sm:right-0 sm:top-0 sm:ml-4"
                            onClick={deleteCustomDomain}
                          >
                            Remove
                          </button>
                        </div>
                        {currentWebsite.customDomain !==
                          currentWebsite.verifiedDomain && (
                          <div>
                            <h2 className="mb-4 text-lg font-bold">
                              Complete your nameserver setup:
                            </h2>
                            <div className="mb-4 font-semibold">
                              1. Log in to your domain registrar.
                            </div>
                            {website.cloudflareOriginalNameservers && (
                              <>
                                <div className="mb-4 font-light">
                                  Remove these nameservers
                                </div>
                                <div className="mb-8 border-l-2 border-gray-300 pl-2 font-light">
                                  {website.cloudflareOriginalNameservers.map(
                                    (nameserver) => {
                                      return (
                                        <div className="ml-4" key={nameserver}>
                                          {nameserver}
                                        </div>
                                      )
                                    }
                                  )}
                                </div>
                                <div className="mb-4 font-semibold">
                                  2. Replace with Cloudflare's nameservers:
                                </div>
                              </>
                            )}
                            {!website.cloudflareOriginalNameservers && (
                              <div>
                                <div className="mb-4 font-semibold">
                                  2. Change your nameservers to our{' '}
                                  {nameservers && nameservers.length}{' '}
                                  nameservers:
                                </div>
                              </div>
                            )}
                            <div className="ml-8">
                              {nameservers &&
                                nameservers.map((nameserver, index) => {
                                  return (
                                    <div key={nameserver} className="mb-8">
                                      <div className="mb-2 flex flex-row items-center">
                                        <div className="ml-2 font-semibold">
                                          Nameserver {index + 1}
                                        </div>
                                      </div>
                                      <div className="mb-4 border-l-2 border-gray-300 pl-2 font-light">
                                        {nameserver}
                                      </div>
                                      <button
                                        id={'nameserver-text-' + index}
                                        onClick={() => {
                                          copyToClipboard(nameserver)
                                          document.getElementById(
                                            'nameserver-text-' + index
                                          ).innerHTML =
                                            'Copied text to clipboard'
                                          setTimeout(() => {
                                            document.getElementById(
                                              'nameserver-text-' + index
                                            ).innerHTML = 'Click to copy'
                                          }, 2000)
                                        }}
                                        className="text-sm underline"
                                      >
                                        Click to copy
                                      </button>
                                    </div>
                                  )
                                })}
                            </div>
                            <details
                              open=""
                              className="mb-8 border border-gray-400"
                            >
                              <summary className="mb-4 bg-gray-400 p-4">
                                Need help? Click here for provider-specific
                                instructions
                              </summary>
                              <div className="px-4">
                                <p className="mb-4">
                                  This is not an exhaustive list of
                                  provider-specific instructions, but the
                                  following links may be helpful:
                                </p>
                                <ul>
                                  <li>
                                    <a
                                      href="https://help.1and1.com/domains-c36931/manage-domains-c79822/dns-c37586/use-your-own-name-server-for-a-1and1-domain-a594904.html"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        1and1
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://help.101domain.com/domain-management/nameservers-dns"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        101Domain
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://docs.aws.amazon.com/Route53/latest/DeveloperGuide/domain-name-servers-glue-records.html#domain-name-servers-glue-records-adding-changing"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        Amazon
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://help.blacknight.com/hc/en-us/articles/212512229-Changing-nameservers-in-cp-blacknight-com"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        Blacknight
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://www.bluehost.com/help/article/custom-nameservers#name-server"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        BlueHost
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://directnic.com/knowledge/article/33:how%2Bdo%2Bi%2Bmodify%2Bname%2Bservers%2Bfor%2Bmy%2Bdomain%2Bname%253F"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        DirectNIC
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="http://www.dnsmadeeasy.com/support/faq/"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        DNSMadeEasy
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://www.domain.com/help/article/domain-management-how-to-update-nameservers"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        Domain.com
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://www.dotster.com/help/article/domain-management-how-to-update-nameservers"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        Dotster
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://help.dreamhost.com/hc/en-us/articles/360038897151"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        DreamHost
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://www.easydns.com/nameservers/"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        EasyDNS
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="http://www.enom.com/kb/kb/kb_0086_how-to-change-dns.htm"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        Enom
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="http://www1.domain.com/help/article/domain-management-how-to-update-nameservers"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        Fast Domain
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://billing.flokinet.is/index.php?rp%3D/knowledgebase/57/Nameserver-changes.html"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        FlokiNET
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://wiki.gandi.net/en/dns/change"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        Gandi
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://www.godaddy.com/help/change-nameservers-for-your-domain-names-664"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        GoDaddy
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://support.google.com/domains/answer/3290309?hl%3Den"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        Google Domains
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="http://support.hostgator.com/articles/hosting-guide/lets-get-started/dns-name-servers/how-do-i-change-my-dns-or-name-servers"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        HostGator
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://hostico.ro/docs/setarea-nameserverelor-din-contul-de-client-hostico/"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        Hostico
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://my.hostmonster.com/cgi/help/222"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        HostMonster
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://internetbs.net/faq/content/1/13/en/how-to-update-the-list-of-dns-nameservers-linked-to-a-domain.html?highlight%3Dnameservers"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        Internetdbs
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://www.ipage.com/help/article/domain-management-how-to-update-nameservers"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        iPage
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://mediatemple.net/community/products/dv/204643220/how-do-i-edit-my-domain%27s-nameservers"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        MediaTemple
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://support.melbourneit.com.au/articles/help/Domain-Name-Administration-FAQ/?q%3Dedit%2Bnameservers%26fs%3DSearch%26pn%3D1"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        MelbourneIT
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://faq.moniker.com/register-own-nameservers"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        Moniker
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://www.name.com/support/articles/205934547-Changing-Your-Name-Servers"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        Name.com
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://www.namecheap.com/support/knowledgebase/article.aspx/767/10/how-can-i-change-the-nameservers-for-my-domain"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        NameCheap
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://www.networksolutions.com/manage-it/edit-nameservers.jsp"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        Network Solutions
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://docs.ovh.com/gb/en/domains/web_hosting_general_information_about_dns_servers/#step-2-edit-your-domains-dns-servers"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        OVH
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://kb.porkbun.com/article/22-how-to-change-your-nameservers"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        Porkbun
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://support.rackspace.com/how-to/rackspace-name-servers/"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        Rackspace
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://knowledge.web.com/subjects/article/KA-01114/en-us"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        Register
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://knowledge.web.com/subjects/article/KA-01114/en-us"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        Site5
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://console.bluemix.net/docs/infrastructure/dns/add-edit-custom-name-servers.html#add-edit-or-delete-custom-name-servers-for-a-domain"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        Softlayer
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="http://www.tucowsdomains.com/name-server-dns-changes/how-do-i-change-my-name-servers-dns/"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        Tucows
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="http://support.hostgator.com/articles/how-to-change-name-servers-with-yahoo-com"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        Yahoo!
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://www.yola.com/tutorials/article/Changing-the-name-servers-1285944436498/Publishing_domains_and_email"
                                      className="text-blue-500"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="DocsMarkdown--link-content">
                                        Yola
                                      </span>
                                      <span className="ml-2" aria-hidden="true">
                                        <i className="fa fa-arrow-up-right-from-square"></i>
                                      </span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </details>
                            <div className="mb-4">
                              <span className="font-bold">Save</span> your
                              changes.
                            </div>
                            <div className="mb-4">
                              We periodically scan to see if you've made the
                              changes and will automatically verify your domain
                              once the changes are detected.
                            </div>
                            <div className="mb-12">
                              After you change the name servers, it can take 5
                              minutes to 48 hours for the changes to take
                              effect.
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          {tabs[3].current && (
            <div className="mt-8 flex flex-col px-6">
              <h1 className="mb-6 text-center text-xl font-bold sm:text-left">
                Waitlist Signups
              </h1>
              <div className="mb-12 w-full max-w-2xl">
                <WaitlistSignupsCell websiteId={website.id} />
              </div>
            </div>
          )}

          {tabs[4].current && (
            <div className="mt-8 flex flex-col px-6">
              <h1 className="mb-6 text-center text-xl font-bold sm:text-left">
                Delete Website
              </h1>
              <div className="mb-6 text-sm text-red-500">
                <div className="mb-4">
                  This will completely delete this website and all the work
                  you've done
                </div>
                <div>This cannot be undone</div>
              </div>
              {!deleteErrorString && !isDeletingWebsite && (
                <div className="my-4">
                  <button
                    type="button"
                    className="rounded bg-red-500 px-4 py-2 font-semibold text-white hover:bg-red-600"
                    onClick={deleteWebsiteClicked}
                  >
                    <span>Delete This Website</span>
                  </button>
                </div>
              )}
              {!deleteErrorString && isDeletingWebsite && (
                <div className="my-4">
                  <button
                    type="button"
                    disabled={true}
                    className="rounded bg-red-500 px-4 py-2 font-semibold text-white hover:bg-red-600"
                  >
                    <span>
                      <i className="fa-regular fa-spinner-third mr-2 animate-spin"></i>
                      Deleting...
                    </span>
                  </button>
                </div>
              )}
              {deleteErrorString && (
                <div className="my-4 text-red-500">{deleteErrorString}</div>
              )}
            </div>
          )}
        </div>
      </div>
      <Transition appear show={isShowingEmailVerificationModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[100]"
          onClose={() => {
            setIsShowingEmailVerificationModal(false)
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative w-full max-w-xl transform overflow-hidden rounded-2xl bg-gray-100 p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h2"
                    className="text-center text-2xl font-medium leading-6 text-gray-900"
                  >
                    You Must Verify Your Email Before Upgrading
                  </Dialog.Title>
                  <div className="mt-12 text-center text-xl">
                    Check your email for a verification link then try again
                  </div>
                  <div className="mt-4 text-center text-sm">
                    Email us at support@landingsite.ai if you have any issues
                  </div>
                  <div className="mt-8 flex items-center justify-center">
                    <button
                      className="rounded-md border border-gray-300 px-4 py-2 text-gray-900 hover:bg-gray-200"
                      onClick={() => {
                        setIsShowingEmailVerificationModal(false)
                      }}
                    >
                      Ok
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default SettingsPanel
