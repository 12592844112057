type ChatLayoutProps = {
  children?: React.ReactNode
}

const ChatLayout = ({ children }: ChatLayoutProps) => {
  return (
    <>
      <main className="flex h-full overflow-hidden">
        <div className="relative flex w-full overflow-hidden bg-[#f2f2f2]">
          {children}
        </div>
      </main>
    </>
  )
}

export default ChatLayout
