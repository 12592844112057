// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { Set, Router, Route, PrivateSet } from '@redwoodjs/router'

import AppNavBarLayout from 'src/layouts/AppNavBarLayout/AppNavBarLayout'
import ChatLayout from 'src/layouts/ChatLayout'
import FineTuningNavBarLayout from 'src/layouts/FineTuningNavBarLayout/FineTuningNavBarLayout'
import ScaffoldLayout from 'src/layouts/ScaffoldLayout'
import TrainingWebsitesNavBarLayout from 'src/layouts/TrainingWebsitesNavBarLayout/TrainingWebsitesNavBarLayout'

import { useAuth } from './auth'
import WebsiteChatPage from './pages/WebsiteChatPage/WebsiteChatPage'

const Routes = () => {
  return (
    <Router useAuth={useAuth}>
      <PrivateSet unauthenticated="login" roles="admin">
        <Set wrap={ScaffoldLayout} title="KvStorages" titleTo="adminKvStorages" buttonLabel="New KvStorage" buttonTo="adminNewKvStorage">
          <Route path="/admin/kv-storages/new" page={AdminKVStorageNewKVStoragePage} name="adminNewKvStorage" />
          <Route path="/admin/kv-storages/{id}/edit" page={AdminKVStorageEditKVStoragePage} name="adminEditKvStorage" />
          <Route path="/admin/kv-storages/{id}" page={AdminKVStorageKVStoragePage} name="adminKvStorage" />
          <Route path="/admin/kv-storages" page={AdminKVStorageKVStoragesPage} name="adminKvStorages" />
        </Set>
      </PrivateSet>
      <PrivateSet unauthenticated="login" roles="admin">
        <Set wrap={ScaffoldLayout} title="Templates" titleTo="adminTemplates" buttonLabel="New Template" buttonTo="adminNewTemplate">
          <Route path="/admin/templates/new" page={AdminTemplateNewTemplatePage} name="adminNewTemplate" />
          <Route path="/admin/templates/{id}/edit" page={AdminTemplateEditTemplatePage} name="adminEditTemplate" />
          <Route path="/admin/templates/{id}" page={AdminTemplateTemplatePage} name="adminTemplate" />
          <Route path="/admin/templates" page={AdminTemplateTemplatesPage} name="adminTemplates" />
        </Set>
      </PrivateSet>
      <PrivateSet unauthenticated="login" roles="admin">
        <Set wrap={FineTuningNavBarLayout}>
          <Route path="/fine-tuning/playground" page={FineTuningPlaygroundPage} name="fineTuningPlayground" />
          <Route path="/fine-tuning/examples" page={FineTuningExamplesPage} name="fineTuningExamples" />
        </Set>
      </PrivateSet>
      <PrivateSet unauthenticated="login" roles={['admin', 'training']}>
        <Set wrap={TrainingWebsitesNavBarLayout}>
          <Route path="/training-websites/new" page={TrainingWebsitesNewTrainingWebsitePage} name="newTrainingWebsite" />
          <Route path="/training-websites/{id}" page={TrainingWebsitesEditPage} name="editTrainingWebsite" />
          <Route path="/training-websites" page={TrainingWebsitesPage} name="trainingWebsites" />
          <Route path="/template-editor" page={TemplateEditorPage} name="templateEditor" />
          <Route path="/template-sections" page={TemplateSectionsPage} name="templateSections" />
          <Route path="/template-sections/{id}/edit" page={TemplateSectionEditPage} name="editTemplateSection" />
        </Set>
      </PrivateSet>
      <PrivateSet unauthenticated="login" roles={['admin', 'beta']}>
        <Set wrap={ChatLayout}>
          <Route path="/website-chat/{id}" page={WebsiteChatPage} name="websiteChat" />
        </Set>
      </PrivateSet>
      <PrivateSet unauthenticated="login">
        <Set wrap={AppNavBarLayout}>
          <Route path="/" page={HomePage} name="home" />
          <Route path="/plans" page={PlansPage} name="plans" />
        </Set>
      </PrivateSet>
      <Set wrap={AppNavBarLayout}>
        <Route path="/website/{id}" page={WebsitePage} name="website" />
        <Route path="/website/{id}/settings" page={WebsiteSettingsPage} name="websiteSettings" />
        <Route path="/website/{id}/plans" page={WebsiteSettingsPage} name="websitePlansSettings" />
        <Route path="/website/{id}/custom-domain" page={WebsiteSettingsPage} name="websiteCustomDomainSettings" />
        <Route path="/website/{id}/waitlist" page={WebsiteSettingsPage} name="websiteWaitlistSettings" />
        <Route path="/website/{id}/delete-website" page={WebsiteSettingsPage} name="websiteDeleteWebsiteSettings" />
        <Route path="/website/{id}/blog-editor" page={BlogEditorPage} name="blogEditor" />
        <Route path="/website/{id}/blog-editor/topics" page={BlogEditorPage} name="blogEditorTopics" />
        <Route path="/website/{id}/blog-post-editor/new" page={BlogPostEditorPage} name="blogPostEditor" />
        <Route path="/website/{id}/blog-post-editor/{blogPostId}" page={BlogPostEditorPage} name="blogPostEditorNew" />
      </Set>
      <Route path="/template-builder/1" page={TemplateOneBuilderPage} name="templateOneBuilder" />
      <Route path="/template-builder/2" page={TemplateTwoBuilderPage} name="templateTwoBuilder" />
      <Route path="/template-builder/3" page={TemplateThreeBuilderPage} name="templateThreeBuilder" />
      <Route path="/template-builder/4" page={TemplateFourBuilderPage} name="templateFourBuilder" />
      <Route path="/template-builder/5" page={TemplateFiveBuilderPage} name="templateFiveBuilder" />
      <Route path="/template-builder/6" page={TemplateSixBuilderPage} name="templateSixBuilder" />
      <Route path="/template-builder/7" page={TemplateSevenBuilderPage} name="templateSevenBuilder" />
      <Route path="/template-builder/8" page={TemplateEightBuilderPage} name="templateEightBuilder" />
      <Route path="/template-builder/9" page={TemplateNineBuilderPage} name="templateNineBuilder" />
      <Route path="/template-builder/10" page={TemplateTenBuilderPage} name="templateTenBuilder" />
      <Route path="/login-success" page={LoginSuccessPage} name="loginSuccess" />
      <Route path="/logout" page={LogoutPage} name="logout" />
      <Route path="/signup" page={SignupPage} name="signup" />
      <Route path="/login" page={LoginPage} name="login" />
      <Route path="/claim" page={ClaimPage} name="claim" />
      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes
