import { Fragment, useState } from 'react'

import { Listbox, Transition } from '@headlessui/react'
import { Website } from 'types/graphql'

import { PageWithCodeSections } from '../PageFrame/PageFrame'

type ToolbarProps = {
  buttons: string[]
  website: Website
  activePage?: PageWithCodeSections
  onViewportWidthChange?: (device: string) => void
  onNewPageClicked?: () => void
  changeActivePage?: (page: PageWithCodeSections) => void
  navigateToPanel?: (panelName: string) => void
  onUndoClick?: () => void
  onRedoClick?: () => void
  undoDisabled?: boolean
  redoDisabled?: boolean
}
const Toolbar = ({
  buttons,
  website,
  activePage,
  onViewportWidthChange,
  onNewPageClicked,
  changeActivePage,
  navigateToPanel,
  onUndoClick,
  onRedoClick,
  undoDisabled,
  redoDisabled,
}: ToolbarProps) => {
  const [websiteSizePick, setWebsiteSizePick] = useState('desktop')

  const changeViewport = (size: 'mobile' | 'tablet' | 'laptop' | 'desktop') => {
    setWebsiteSizePick(size)
    onViewportWidthChange && onViewportWidthChange(size)
  }

  return (
    <div className="mb-4 flex flex-row items-center justify-between rounded bg-white p-2 shadow-md">
      <div className="flex items-center space-x-2">
        {/* Font Style Controls */}
        {buttons.includes('back to website') && (
          <button
            className="rounded bg-white p-2"
            onClick={() => navigateToPanel('website')}
          >
            <i className="fa-regular fa-arrow-left mr-2"></i>
            Go To Website
          </button>
        )}
        {buttons.includes('formatting options') && (
          <div className="group relative">
            <button className="rounded border p-2 hover:bg-gray-100">
              <i className="fa-regular fa-font-case mr-2"></i>
              <i className="fa-regular fa-angle-down"></i>
            </button>
            <span className="pointer-events-none absolute bottom-[-30px] left-0 z-10 w-32 rounded bg-gray-200 px-2 py-1 text-xs opacity-0 transition-opacity group-hover:opacity-100">
              Formatting Options
            </span>
          </div>
        )}
        {/* Font Size Controls */}
        {/* <div className="flex items-center space-x-2">
          <input
            type="number"
            className="w-16 rounded bg-[#3c3c3c] p-2 text-center"
            defaultValue={14}
          />
        </div> */}
        {/* Font Boldness Dropdown */}
        {/* <div className="relative">
          <button className="flex items-center space-x-1 rounded bg-[#3c3c3c] p-2">
            <span>Boldness</span>
            <i className="fa-regular fa-angle-down"></i>
          </button>
          <div className="absolute mt-1 hidden w-32 bg-white text-black">
            <ul>
              <li className="p-2 hover:bg-gray-200">Normal</li>
              <li className="p-2 hover:bg-gray-200">Bold</li>
              <li className="p-2 hover:bg-gray-200">Bolder</li>
            </ul>
          </div>
        </div> */}
        {buttons.includes('undo') && (
          <div className="group relative">
            <button
              className="rounded bg-white p-2 disabled:opacity-50"
              onClick={onUndoClick}
              disabled={undoDisabled}
            >
              <i className="fa-regular fa-undo"></i>
            </button>
            <span className="pointer-events-none absolute bottom-[-30px] left-1/2 z-10 -translate-x-1/2 transform rounded bg-gray-200 px-2 py-1 text-xs opacity-0 transition-opacity group-hover:opacity-100">
              Undo
            </span>
          </div>
        )}
        {buttons.includes('redo') && (
          <div className="group relative">
            <button
              className="rounded bg-white p-2 disabled:opacity-50"
              onClick={onRedoClick}
              disabled={redoDisabled}
            >
              <i className="fa-regular fa-redo"></i>
            </button>
            <span className="pointer-events-none absolute bottom-[-30px] left-1/2 z-10 -translate-x-1/2 transform rounded bg-gray-200 px-2 py-1 text-xs opacity-0 transition-opacity group-hover:opacity-100">
              Redo
            </span>
          </div>
        )}
      </div>

      {buttons.includes('viewport width') && (
        <div className="flex items-center space-x-2">
          <div className="group relative">
            <button
              className={
                (websiteSizePick === 'mobile'
                  ? 'bg-gray-100 '
                  : 'bg-white hover:bg-gray-100 ') + 'rounded px-2 py-1'
              }
              onClick={() => changeViewport('mobile')}
            >
              <i className="fa-regular fa-mobile-notch"></i>
              <span className="pointer-events-none absolute bottom-[-30px] left-1/2 z-10 -translate-x-1/2 transform rounded bg-gray-200 px-2 py-1 text-xs opacity-0 transition-opacity group-hover:opacity-100">
                Mobile
              </span>
            </button>
          </div>
          <div className="group relative">
            <button
              className={
                (websiteSizePick === 'tablet'
                  ? 'bg-gray-100 '
                  : 'bg-white hover:bg-gray-100 ') + 'rounded px-2 py-1'
              }
              onClick={() => changeViewport('tablet')}
            >
              <i className="fa-regular fa-tablet"></i>
            </button>
            <span className="pointer-events-none absolute bottom-[-30px] left-1/2 z-10 -translate-x-1/2 transform rounded bg-gray-200 px-2 py-1 text-xs opacity-0 transition-opacity group-hover:opacity-100">
              Tablet
            </span>
          </div>
          <div className="group relative">
            <button
              className={
                (websiteSizePick === 'laptop'
                  ? 'bg-gray-100 '
                  : 'bg-white hover:bg-gray-100 ') + 'rounded px-2 py-1'
              }
              onClick={() => changeViewport('laptop')}
            >
              <i className="fa-regular fa-laptop"></i>
            </button>
            <span className="pointer-events-none absolute bottom-[-30px] left-1/2 z-10 -translate-x-1/2 transform rounded bg-gray-200 px-2 py-1 text-xs opacity-0 transition-opacity group-hover:opacity-100">
              Laptop
            </span>
          </div>
          <div className="group relative">
            <button
              className={
                (websiteSizePick === 'desktop'
                  ? 'bg-gray-100 '
                  : 'bg-white hover:bg-gray-100 ') + 'rounded px-2 py-1'
              }
              onClick={() => changeViewport('desktop')}
            >
              <i className="fa-regular fa-desktop"></i>
            </button>
            <span className="pointer-events-none absolute bottom-[-30px] left-1/2 z-10 -translate-x-1/2 transform rounded bg-gray-200 px-2 py-1 text-xs opacity-0 transition-opacity group-hover:opacity-100">
              Desktop
            </span>
          </div>
        </div>
      )}

      <div className="flex space-x-2">
        {buttons.includes('page picker') && (
          <PageListBox
            activePage={activePage}
            pages={website.Pages}
            onNewPageClicked={onNewPageClicked}
            changeActivePage={changeActivePage}
          />
        )}
        {buttons.includes('settings') && (
          <div className="group relative">
            <button
              className="rounded bg-white p-2"
              onClick={() => navigateToPanel('settings')}
            >
              <i className="fa-regular fa-gear"></i>
            </button>
            <span className="pointer-events-none absolute bottom-[-30px] left-1/2 z-10 -translate-x-1/2 transform rounded bg-gray-200 px-2 py-1 text-xs opacity-0 transition-opacity group-hover:opacity-100">
              Settings
            </span>
          </div>
        )}
        {buttons.includes('preview') && (
          <a
            href={`/website-preview/${website.id}`}
            target="_blank"
            className="rounded bg-white p-2"
            rel="noreferrer"
          >
            <i className="fa-regular fa-globe-pointer mr-2"></i>
            Preview
          </a>
        )}
        {buttons.includes('publish') && (
          <button className="rounded border p-2">
            <i className="fa-regular fa-upload mr-2"></i>
            Publish To Website
          </button>
        )}
      </div>
    </div>
  )
}

const PageListBox = ({
  activePage,
  pages,
  onNewPageClicked,
  changeActivePage,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Listbox
      value={activePage}
      onChange={(page) => {
        setIsOpen(false)
        changeActivePage(page)
      }}
    >
      <div id="page-dropdown" className="relative max-w-[200px] flex-grow">
        <Listbox.Button
          className="relative h-full w-full rounded bg-white py-2 pl-3 pr-10 text-left font-normal focus:outline-none focus-visible:border-blue-600 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-600"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="block truncate">{activePage.name}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <i className="fa-solid fa-angle-down" aria-hidden="true"></i>
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          show={isOpen}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-[200px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {pages.map((page) => {
              return (
                <Listbox.Option
                  key={page.id}
                  value={page}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-10 ${
                      active ? 'bg-gray-200 text-[#333]' : 'text-gray-900'
                    }`
                  }
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {page.name}
                      </span>
                      {page.isHomepage === false && (
                        <button
                          // onClick={(e) => {
                          //   e.preventDefault()
                          //   confirmDeletePage(page)
                          // }}
                          className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 hover:text-gray-600"
                        >
                          <i className="fa-solid fa-trash"></i>
                        </button>
                      )}
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-[#333]">
                          <i
                            className="fa-solid fa-check"
                            aria-hidden="true"
                          ></i>
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              )
            })}
            <button
              onClick={() => {
                onNewPageClicked && onNewPageClicked()
                setIsOpen(false)
              }}
              className="w-full border-t border-gray-200 py-2 pl-3 text-left text-black"
            >
              <i className="fa-regular fa-plus mr-2 pr-2"></i>
              <span>New Page</span>
            </button>
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}

export default Toolbar
