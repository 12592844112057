import { useEffect, useRef, useState } from 'react'

import * as cheerio from 'cheerio'
import type {
  FindWebsitePanelQuery,
  FindWebsitePanelQueryVariables,
  UpdateWebsiteInput,
  Website,
} from 'types/graphql'

import { Metadata } from '@redwoodjs/web'
import { type TypedDocumentNode, useQuery, useMutation } from '@redwoodjs/web'

import ChatPanel, { ChatPanelHandler } from 'src/components/Chat/ChatPanel'
import SettingsPanel from 'src/components/Chat/SettingsPanel'
import Toolbar from 'src/components/Chat/Toolbar'
import WebsitePanel, {
  WebsitePanelHandler,
} from 'src/components/Chat/WebsitePanel'
import {
  CodeSection,
  FrameElementEvent,
  PageWithCodeSections,
} from 'src/components/PageFrame/PageFrame'

type WebsiteWidth = 'mobile' | 'tablet' | 'laptop' | 'desktop'

const FIND_WEBSITE: TypedDocumentNode<
  FindWebsitePanelQuery,
  FindWebsitePanelQueryVariables
> = gql`
  query FindWebsitePanelQuery($id: String!) {
    website: website(id: $id) {
      id
      name
      url
      businessName
      businessAbout
      createdAt
      startedGeneratingAt
      finishedGeneratingAt
      onboardingQuestions
      onboardingAnswers
      hasRunOnboardingFunctions
      additionalInformationFields
      notifyWhenFinishedEmail
      nextStepStateIndex
      hasCompletedOnboarding
      hasCompletedBlogOnboarding
      hasAddedBlogLinkToHomepage
      uniqueUserToken
      headerCodeSection
      footerCodeSection
      colorPalette
      googleAnalyticsId
      contactFormEmail
      agencyShortCode
      scriptsInHtml
      contentInHead
      customDomain
      verifiedDomain
      stripePlan
      cloudflareZoneId
      cloudflareZoneActivatedOn
      cloudflareOriginalDNSHost
      cloudflareOriginalNameservers
      cloudflareOriginalRegistrar
      cloudflareNameservers
      nameservers
      waitingForSSLCertificate
      user {
        id
        agencyOwnerName
      }
      Pages {
        id
        name
        path
        isHomepage
        html
        codeSections
        imagesKeyword
        primaryColor
        startedGeneratingAt
        finishedGeneratingAt
        hasAppliedPrimaryColorOnFirstLoad
        seoDescription
        seoTitle
        seoMetaTags
      }
    }
  }
`

const SAVE_WEBSITE = gql`
  mutation SaveChatWebsiteMutation($id: String!, $input: UpdateWebsiteInput!) {
    updateWebsite(id: $id, input: $input) {
      __typename
      id
      googleAnalyticsId
      headerCodeSection
      footerCodeSection
    }
  }
`

const SAVE_ACTIVE_PAGE = gql`
  mutation SaveActivePageMutation($id: String!, $input: UpdatePageInput!) {
    updatePage(id: $id, input: $input) {
      __typename
      id
      codeSections
      imagesKeyword
      primaryColor
      hasAppliedPrimaryColorOnFirstLoad
    }
  }
`

const START_BUILDING_WEBSITE = gql`
  mutation StartBuildingWebsiteMutation($id: String!) {
    startBuildingWebsite(id: $id) {
      __typename
      id
      startedGeneratingAt
    }
  }
`

const WebsiteChatPage = ({ id }: { id: string }) => {
  const websitePanelRef = useRef<WebsitePanelHandler>(null)
  const chatPanelRef = useRef<ChatPanelHandler>(null)
  const skipHistoryUpdateRef = useRef<boolean>(false)
  const isGeneratingWebsiteRef = useRef<boolean>(false)

  const [clickedElement, setClickedElement] = useState<FrameElementEvent>(null)
  const [panelName, setPanelName] = useState('website')
  const [clickedElementEvent, setClickedElementEvent] =
    useState<FrameElementEvent | null>(null)
  const [website, setWebsite] = useState<
    Website & {
      headerCodeSection: CodeSection
      footerCodeSection: CodeSection
    }
  >(null)
  const [activePage, setActivePage] = useState<PageWithCodeSections>(null)
  const [isDataChanged, setIsDataChanged] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [websiteWidth, setWebsiteWidth] = useState<WebsiteWidth>('desktop')
  const [history, setHistory] = useState([])
  const [historyIndex, setHistoryIndex] = useState(0)
  const [shouldDisableChatSubmit, setShouldDisableChatSubmit] = useState(false)

  const { loading, error, refetch } = useQuery<FindWebsitePanelQuery>(
    FIND_WEBSITE,
    {
      variables: { id },
      onCompleted(data) {
        setWebsite(data.website as any)
        if (activePage) {
          setActivePage(
            (data.website.Pages as PageWithCodeSections[]).find(
              (v) => v.id === activePage.id
            )
          )
        }
      },
    }
  )
  const [saveWebsite] = useMutation(SAVE_WEBSITE)
  const [saveActivePage] = useMutation(SAVE_ACTIVE_PAGE)
  const [startBuildingWebsite] = useMutation(START_BUILDING_WEBSITE)

  useEffect(() => {
    if (
      history.length === 0 &&
      website &&
      website.finishedGeneratingAt &&
      activePage &&
      activePage.codeSections
    ) {
      setHistory([
        {
          activePage,
          headerCodeSection: website.headerCodeSection,
          footerCodeSection: website.footerCodeSection,
        },
      ])
    }
  }, [activePage, website])

  useEffect(() => {
    if (!website) {
      return
    }

    if (website && !activePage) {
      setActivePage(
        (website.Pages as PageWithCodeSections[]).find((v) => v.isHomepage)
      )
    }

    if (website && !website.startedGeneratingAt) {
      if (isGeneratingWebsiteRef.current === true) {
        return // already generating
      }

      console.log('generating new website...')

      isGeneratingWebsiteRef.current = true
      setShouldDisableChatSubmit(true)

      chatPanelRef.current.sendAssistantMessage(
        "I'm building your website based on the information you gave me. Feel free to ask me any questions in the meantime."
      )

      //this should set website.startedGeneratingAt on the server so we dont do this twice from a page reload
      startBuildingWebsite({
        variables: {
          id: website.id,
        },
      })

      //building could take a while so we are going to need to fire
      //off a message and keep polling the server until we see website.finishedGeneratingAt
    }
  }, [website])

  useEffect(() => {
    console.log('in useEffect for website', website)
    if (!website) {
      return
    }

    console.log('website:', website)
    console.log('website finishedGeneratingAt:', website?.finishedGeneratingAt)

    // Check if finishedGeneratingAt is null, which means we need to keep refreshing
    if (website && !website.finishedGeneratingAt) {
      console.log('refetching website...')
      const intervalId = setInterval(() => {
        if (website.finishedGeneratingAt) {
          clearInterval(intervalId)
          isGeneratingWebsiteRef.current = false
        }

        refetch()
      }, 5000) // 5 second interval

      // Cleanup function that clears the interval when the component is unmounted or finishedGeneratingAt is not null
      return () => {
        clearInterval(intervalId)
      }
    }
  }, [website?.startedGeneratingAt, website?.finishedGeneratingAt, refetch])

  useEffect(() => {
    // save page and website
    if (!isDataChanged) {
      return
    }
    setIsDataChanged(false)
    saveThisPage()
  }, [isDataChanged])

  const onFrameElementClick = (event: FrameElementEvent) => {
    setClickedElement(event)
    setClickedElementEvent(event)
  }

  const clearClickedElement = () => {
    console.log('clearing clicked element in website chat page')
    setClickedElement(null)
    setClickedElementEvent(null)
    if (websitePanelRef.current) {
      websitePanelRef.current.clearClickedElement()
    }
  }

  const saveThisPage = async () => {
    if (isSaving) {
      return
    }
    setIsSaving(true)

    const { updatedPage, headerCodeSection, footerCodeSection } =
      websitePanelRef.current.getUpdatedPage()

    await saveActivePage({
      variables: {
        id: updatedPage.id,
        input: {
          codeSections: updatedPage.codeSections,
        },
      },
    })

    const input: UpdateWebsiteInput = {}
    if (headerCodeSection) {
      input.headerCodeSection = headerCodeSection
    }
    if (footerCodeSection) {
      input.footerCodeSection = footerCodeSection
    }
    // only save if there is a change
    if (headerCodeSection || footerCodeSection) {
      await saveWebsite({
        variables: {
          id: website.id,
          input: input,
        },
      })
    }

    if (skipHistoryUpdateRef.current === false) {
      setHistory((prevHistory) => {
        const newHistory = prevHistory.slice(0, historyIndex + 1)
        newHistory.push({
          activePage,
          headerCodeSection: website.headerCodeSection,
          footerCodeSection: website.footerCodeSection,
        })

        setHistoryIndex(newHistory.length - 1)
        return newHistory
      })
    }

    skipHistoryUpdateRef.current = false

    setIsSaving(false)
  }

  const updateHtmlFromUserPrompt = async (
    prompt: string
  ): Promise<{ message: string; ok: boolean }> => {
    const success = () => ({
      message: 'Website updated successfully',
      ok: true,
    })
    const failure = (error: string) => ({
      message: `Unable to update website: ${error}`,
      ok: false,
    })

    if (!clickedElementEvent) {
      return failure('No element selected')
    }

    if (!website.finishedGeneratingAt) {
      return failure(
        "Your website is still generating. Please try again once it's done"
      )
    }
    //send a request to getHtmlFromPrompt with prompt, and codeSection where clickedElementContent is the html of the element that was clicked

    const codeSectionElement = clickedElementEvent.codeSectionElement

    const codeSection = codeSectionElement
    const clickedElement = clickedElementEvent.element

    let apiResponse: Response

    try {
      apiResponse = await fetch(
        process.env.LAMBDA_GET_HTML_FROM_USER_PROMPT ||
          `${process.env.API_BASE_URL}/getHtmlFromUserPrompt`,
        {
          method: 'POST',
          body: JSON.stringify({
            id: website.id,
            prompt,
            codeSectionHtml: codeSection.outerHTML,
            clickedElementHtml: clickedElement.outerHTML,
          }),
        }
      )
    } catch (error) {
      console.log(error)
      return failure(error)
    }

    if (!apiResponse.ok) {
      console.log('error:', apiResponse)
      return failure(await apiResponse.text())
    }

    const res = await apiResponse.json()

    //since we send the code section to the backend with the .clicked-element class on our element
    //it sends it right back to us with the .clicked-element class on it
    //so we need to remove that class before we set the html
    // TODO move all this into the getHtmlFromUserPrompt function
    const $ = cheerio.load(res.data)
    $('.clicked-element').removeClass('clicked-element')
    $('.hovered-element').removeClass('hovered-element')
    $('.clicked-code-section').removeClass('clicked-code-section')
    $('[contenteditable]').removeAttr('contenteditable')

    // verify section is structured correctly
    if ($('body').children().length != 1) {
      console.error('section not found in response, or too many sections')
    }

    const firstSection = $('body').children().first()
    // make sure it has the right id
    if (firstSection.attr('id') != codeSectionElement.id) {
      firstSection.attr('id', codeSectionElement.id)
    }
    // remove code-section classes, add back to main container
    $('.code-section').removeClass('code-section')
    firstSection.addClass('code-section')

    const newHtml = $('body').html()

    if (
      website.headerCodeSection &&
      website.headerCodeSection.id == codeSectionElement.id
    ) {
      setWebsite({
        ...website,
        headerCodeSection: {
          ...(website.headerCodeSection as CodeSection),
          html: newHtml,
        },
      })
    }

    setActivePage((prevPage) => ({
      ...prevPage,
      codeSections: prevPage.codeSections.map((section) => {
        if (section.id === codeSection.id) {
          return {
            ...section,
            html: newHtml,
          }
        } else {
          return section
        }
      }),
    }))

    if (
      website.footerCodeSection &&
      website.footerCodeSection.id == codeSectionElement.id
    ) {
      setWebsite({
        ...website,
        footerCodeSection: {
          ...(website.footerCodeSection as CodeSection),
          html: newHtml,
        },
      })
    }

    setIsDataChanged(true)
    setClickedElement(null)
    setClickedElementEvent(null)
    return success()
  }

  const functions = {
    editHtmlOfSelectedElement: async (prompt: string) => {
      return await updateHtmlFromUserPrompt(prompt)
    },
    navigateToPanel: (toolResponse: string) => {
      let res = { panelName: 'website' }
      try {
        res = JSON.parse(toolResponse)
      } catch (e) {
        return {
          message: 'Error parsing tool response',
          ok: false,
        }
      }

      navigateToPanel(res.panelName)

      return {
        message: 'Navigated to settings panel',
        ok: true,
      }
    },
    addNewSectionAboveSelectedSection: async (toolResponse: string) => {
      let res = { sectionUserRequest: '' }
      try {
        res = JSON.parse(toolResponse)
      } catch (e) {
        return {
          message: 'Error parsing tool response',
          ok: false,
        }
      }

      const aiResponse = await createNewCodeSectionAbove(res.sectionUserRequest)

      return aiResponse
    },
    addNewSectionBelowSelectedSection: async (toolResponse: string) => {
      let res = { sectionUserRequest: '' }
      try {
        res = JSON.parse(toolResponse)
      } catch (e) {
        return {
          message: 'Error parsing tool response',
          ok: false,
        }
      }

      await createNewCodeSectionBelow(res.sectionUserRequest)

      return {
        message: 'Added new section below selected section',
        ok: true,
      }
    },
  }

  const onSendMessageForUser = (message: string) => {
    // send message to user
  }

  const navigateToPanel = (panelName: string) => {
    setPanelName(panelName)
  }

  const changeViewportWidth = (
    size: 'mobile' | 'tablet' | 'laptop' | 'desktop'
  ) => {
    setWebsiteWidth(size)
  }

  const createNewPage = () => {
    onSendMessageForUser('I want to create a new page for my website')
  }

  const changeActivePage = (page: PageWithCodeSections) => {
    setActivePage(page)
  }

  const onUndoClick = () => {
    console.log('undo')

    setHistoryIndex((prevIndex) => {
      if (prevIndex > 0) {
        const nextIndex = prevIndex - 1
        const historyElement = history[nextIndex]
        setWebsite((websiteVal) => {
          return {
            ...websiteVal,
            headerCodeSection: historyElement.headerCodeSection,
            footerCodeSection: historyElement.footerCodeSection,
          }
        })
        setActivePage(history[nextIndex].activePage)
        skipHistoryUpdateRef.current = true
        setIsDataChanged(true)
        return nextIndex
      }
      return prevIndex
    })
  }

  const onRedoClick = () => {
    console.log('redo')

    setHistoryIndex((prevIndex) => {
      if (prevIndex < history.length - 1) {
        const nextIndex = prevIndex + 1
        const historyElement = history[nextIndex]
        setWebsite((websiteVal) => {
          return {
            ...websiteVal,
            headerCodeSection: historyElement.headerCodeSection,
            footerCodeSection: historyElement.footerCodeSection,
          }
        })
        setActivePage(history[nextIndex].activePage)
        skipHistoryUpdateRef.current = true
        setIsDataChanged(true)
        return nextIndex
      }
      return prevIndex
    })
  }

  const onAddNewCodeSectionAbove = (id: string) => {
    if (!id || !activePage || !activePage.codeSections) {
      return
    }

    chatPanelRef.current.sendUserMessage(
      "I'd like to add a new section above my selected section."
    )
  }

  const onAddNewCodeSectionBelow = (id: string) => {
    if (!id || !activePage || !activePage.codeSections) {
      return
    }

    chatPanelRef.current.sendUserMessage(
      "I'd like to add a new section below my selected section."
    )
  }

  const onMoveClickedCodeSectionUp = (id: string) => {
    if (!id || !activePage || !activePage.codeSections) {
      return
    }

    const codeSections = activePage.codeSections
    const index = codeSections.findIndex(
      (section: CodeSection) => section.id === id
    )

    if (index === 0) {
      return
    }

    const newCodeSections = [...codeSections]
    const temp = newCodeSections[index - 1]
    newCodeSections[index - 1] = newCodeSections[index]
    newCodeSections[index] = temp

    setActivePage((prevPage) => ({
      ...prevPage,
      codeSections: newCodeSections,
    }))

    setIsDataChanged(true)
  }

  const onMoveClickedCodeSectionDown = (id: string) => {
    if (!id || !activePage || !activePage.codeSections) {
      return
    }

    const codeSections = activePage.codeSections
    const index = codeSections.findIndex(
      (section: CodeSection) => section.id === id
    )

    if (index === activePage.codeSections.length - 1) {
      return
    }

    const newCodeSections = [...codeSections]
    const temp = newCodeSections[index + 1]
    newCodeSections[index + 1] = newCodeSections[index]
    newCodeSections[index] = temp

    setActivePage((prevPage) => ({
      ...prevPage,
      codeSections: newCodeSections,
    }))

    setIsDataChanged(true)
  }

  const onDeleteClickedCodeSection = (id: string) => {
    if (!id || !activePage || !activePage.codeSections) {
      return
    }

    const codeSections = activePage.codeSections
    const index = codeSections.findIndex(
      (section: CodeSection) => section.id === id
    )

    const newCodeSections = [...codeSections]
    newCodeSections.splice(index, 1)

    setActivePage((prevPage) => ({
      ...prevPage,
      codeSections: newCodeSections,
    }))
    setIsDataChanged(true)
  }

  const getNewCodeSection = async (prompt: string) => {
    let response: Response

    try {
      response = await fetch(
        process.env.LAMBDA_GET_NEW_CODE_SECTION_FROM_USER_PROMPT ||
          `${process.env.API_BASE_URL}/getNewCodeSectionFromUserPrompt`,
        {
          method: 'POST',
          body: JSON.stringify({
            id: website.id,
            pageId: activePage.id,
            prompt: prompt,
          }),
        }
      )
    } catch (error) {
      console.log(error)
      return
    }

    if (!response.ok) {
      console.log('error:', response)
      return
    }

    const res = await response.json()

    return res.data
  }

  const createNewCodeSectionAbove = async (sectionUserRequest: string) => {
    if (!activePage || !activePage.codeSections) {
      return {
        message:
          'Unable to add new section above selected section. Try refreshing the page',
        ok: false,
      }
    }

    if (!clickedElementEvent || !clickedElementEvent.codeSectionElement) {
      return {
        message: 'No section selected. Please select a section first',
        ok: false,
      }
    }

    const selectedSectionId = clickedElementEvent.codeSectionElement.id

    console.log('creating new code section above', selectedSectionId)
    console.log('sectionUserRequest:', sectionUserRequest)

    const newCodeSection = await getNewCodeSection(sectionUserRequest)

    const codeSections = activePage.codeSections
    const index = codeSections.findIndex(
      (section: CodeSection) => section.id === selectedSectionId
    )

    const newCodeSections = [...codeSections]
    newCodeSections.splice(index, 0, newCodeSection)

    setActivePage((prevPage) => ({
      ...prevPage,
      codeSections: newCodeSections,
    }))
    setIsDataChanged(true)

    return {
      message: 'Successfully created the new section',
      ok: true,
    }
  }

  const createNewCodeSectionBelow = async (sectionUserRequest: string) => {
    if (!activePage || !activePage.codeSections) {
      return {
        message:
          'Unable to add new section above selected section. Try refreshing the page',
        ok: false,
      }
    }

    if (!clickedElementEvent || !clickedElementEvent.codeSectionElement) {
      return {
        message: 'No section selected. Please select a section first',
        ok: false,
      }
    }

    const selectedSectionId = clickedElementEvent.codeSectionElement.id

    console.log(
      'clickedElementEvent.codeSectionElement.id:',
      clickedElementEvent.codeSectionElement.id
    )

    console.log('creating new code section below', selectedSectionId)
    console.log('sectionUserRequest:', sectionUserRequest)

    const newCodeSection = await getNewCodeSection(sectionUserRequest)

    const codeSections = activePage.codeSections
    const index = codeSections.findIndex(
      (section: CodeSection) => section.id === selectedSectionId
    )

    const newCodeSections = [...codeSections]
    newCodeSections.splice(index + 1, 0, newCodeSection)

    setActivePage((prevPage) => ({
      ...prevPage,
      codeSections: newCodeSections,
    }))
    setIsDataChanged(true)

    return {
      message: 'Successfully created the new section',
      ok: true,
    }
  }

  return (
    <>
      <Metadata title="EditWebsite" description="Edit Website" />

      <div className="lg absolute left-0 top-0 flex h-full w-full flex-row lg:static lg:w-auto lg:shrink lg:grow xl:left-[270px]">
        <ChatPanel
          ref={chatPanelRef}
          websiteId={id}
          functions={functions}
          clickedElement={clickedElement}
          handleClearClickedElement={clearClickedElement}
          disableChatSubmit={shouldDisableChatSubmit}
        />
        {/* divider */}
        <div className="p-2"></div>
        <PanelLayout>
          {loading && !website && <div>Loading...</div>}
          {error && (
            <div>
              Sorry, there was an error loading your website. Try refreshing the
              page.
            </div>
          )}
          {panelName === 'website' && website && activePage && (
            <>
              <Toolbar
                buttons={[
                  'formatting options',
                  'undo',
                  'redo',
                  'viewport width',
                  'page picker',
                  'settings',
                  'preview',
                  'publish',
                ]}
                website={website}
                activePage={activePage}
                onViewportWidthChange={changeViewportWidth}
                onNewPageClicked={createNewPage}
                changeActivePage={changeActivePage}
                navigateToPanel={navigateToPanel}
                onUndoClick={onUndoClick}
                onRedoClick={onRedoClick}
                undoDisabled={historyIndex === 0}
                redoDisabled={
                  history.length === 0 ||
                  (history.length > 0 && historyIndex === history.length - 1)
                }
              />
              <WebsitePanel
                ref={websitePanelRef}
                website={website}
                activePage={activePage}
                websiteWidth={websiteWidth}
                onFrameElementClick={onFrameElementClick}
                onAddNewCodeSectionAbove={onAddNewCodeSectionAbove}
                onAddNewCodeSectionBelow={onAddNewCodeSectionBelow}
                onMoveClickedCodeSectionUp={onMoveClickedCodeSectionUp}
                onMoveClickedCodeSectionDown={onMoveClickedCodeSectionDown}
                onDeleteClickedCodeSection={onDeleteClickedCodeSection}
              />
            </>
          )}
          {panelName === 'settings' && website && (
            <>
              <Toolbar
                buttons={['back to website', 'undo', 'redo', 'publish']}
                website={website}
                navigateToPanel={navigateToPanel}
                onUndoClick={() => {}}
                onRedoClick={() => {}}
                undoDisabled={true}
                redoDisabled={true}
              />
              <SettingsPanel website={website} />
            </>
          )}
        </PanelLayout>
      </div>
    </>
  )
}

const PanelLayout = ({ children }) => {
  return (
    <div className="grow overflow-hidden p-4 first:pr-0 last:pl-0">
      <div className="flex h-full w-full">
        <div className="shrink grow basis-full">
          <div className="relative flex h-full min-w-0 flex-col">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default WebsiteChatPage
