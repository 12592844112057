import {
  FC,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react'

import {
  ArrowRight,
  X,
  ArrowCounterClockwise,
  CircleNotch,
  CrosshairSimple,
} from '@phosphor-icons/react'
import classNames from 'classnames'
import { AssistantStream } from 'openai/lib/AssistantStream'
import {
  ImageFile,
  Message,
  Text,
  TextDelta,
} from 'openai/resources/beta/threads/messages'
import {
  RequiredActionFunctionToolCall,
  Run,
} from 'openai/resources/beta/threads/runs/runs'
import {
  ToolCall,
  ToolCallDelta,
} from 'openai/resources/beta/threads/runs/steps'
import { useRemark } from 'react-remark'

import { useAuth } from 'src/auth'

import { FrameElementEvent } from '../PageFrame/PageFrame'

export type ChatPanelHandler = {
  sendAssistantMessage: (message: string) => void
  sendUserMessage: (message: string) => void
}

type ChatPanelProps = {
  websiteId: string
  clickedElement?: FrameElementEvent
  functions: Record<string, (args: any) => any>
  handleClearClickedElement: () => void
  disableChatSubmit?: boolean
}
const ChatPanel = forwardRef((props: ChatPanelProps, ref) => {
  const {
    websiteId,
    functions,
    clickedElement,
    handleClearClickedElement,
    disableChatSubmit,
  } = props

  useImperativeHandle<unknown, ChatPanelHandler>(ref, () => ({
    sendAssistantMessage: async (message) => {
      await loadPromiseRef.current

      sendAssistantMessage(message)
    },
    sendUserMessage: async (message) => {
      await loadPromiseRef.current

      handleSubmit(message)
    },
  }))

  const [messages, setMessages] = useState<
    { role: string; text: string; elementLabel: string }[]
  >([])
  const [loading, setLoading] = useState(true)
  const [userInput, setUserInput] = useState('')
  const [submitDisabled, setSubmitDisabled] = useState(
    disableChatSubmit || false
  )
  const { getToken } = useAuth()
  const [waiting, setWaiting] = useState(false)

  const loadPromiseRef = useRef<Promise<void> | null>(null)

  useEffect(() => {
    const getMessages = async () => {
      //dont let someone add messages while loading
      setSubmitDisabled(true)
      const response = await fetch(
        `${process.env.CLOUDFLARE_WORKER_ASSISTANT_API}/api/website/${websiteId}/messages`,
        {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        }
      )

      const data = await response.json()
      let messages = data.messages || []

      messages = messages.map((message) => ({
        text: message.content[0].text.value,
        role: message.role,
        elementLabel: message.metadata.element_label,
      }))

      setMessages(messages)

      // if there's an ongoing run, restart it
      if (data.run) {
        handleRequiresAction(data.run)
      }

      setSubmitDisabled(disableChatSubmit || false)
      setLoading(false)
    }

    loadPromiseRef.current = getMessages()
  }, [])

  // automatically scroll to bottom of chat
  const messagesEndRef = useRef<HTMLDivElement | null>(null)
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }
  useEffect(() => {
    scrollToBottom()
  }, [messages])

  const sendAssistantMessage = async (text: string) => {
    const response = await fetch(
      `${process.env.CLOUDFLARE_WORKER_ASSISTANT_API}/api/website/${websiteId}/messages`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${await getToken()}`,
        },
        body: JSON.stringify({
          role: 'assistant',
          text,
        }),
      }
    )

    console.log(response)
  }

  const sendUserMessage = async (text: string) => {
    const response = await fetch(
      `${process.env.CLOUDFLARE_WORKER_ASSISTANT_API}/api/website/${websiteId}/messages`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${await getToken()}`,
        },
        body: JSON.stringify({
          text,
          ...(clickedElement
            ? {
                clickedCodeSectionHtml:
                  clickedElement.codeSectionElement.outerHTML,
                clickedElementHtml: clickedElement.element.outerHTML,
                elementLabel: getElementLabel(clickedElement?.element),
              }
            : {}),
        }),
      }
    )

    const stream = AssistantStream.fromReadableStream(response.body)
    handleReadableStream(stream)
  }

  const submitActionResult = async (
    runId: string,
    toolCallOutputs: ToolCallOutput[]
  ) => {
    const response = await fetch(
      `${process.env.CLOUDFLARE_WORKER_ASSISTANT_API}/api/website/${websiteId}/actions`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${await getToken()}`,
        },
        body: JSON.stringify({
          runId: runId,
          toolCallOutputs: toolCallOutputs,
        }),
      }
    )

    const stream = AssistantStream.fromReadableStream(response.body)
    handleReadableStream(stream)
  }

  const resetChat = async () => {
    await fetch(
      `${process.env.CLOUDFLARE_WORKER_ASSISTANT_API}/api/website/${websiteId}/messages`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${await getToken()}`,
        },
      }
    )
    setMessages([])
  }
  const cancelRun = async () => {
    await fetch(
      `${process.env.CLOUDFLARE_WORKER_ASSISTANT_API}/api/website/${websiteId}/actions`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${await getToken()}`,
        },
      }
    )
  }

  const handleSubmit = (messageOverride?: string) => {
    if (loading) {
      return
    }

    const input = messageOverride || userInput
    if (!input.trim()) return
    sendUserMessage(input)
    addMessage(
      'user',
      input,
      clickedElement && getElementLabel(clickedElement?.element)
    )
    setUserInput('')
    setSubmitDisabled(true)
    scrollToBottom()
    setWaiting(true)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      if (submitDisabled) {
        return
      }

      handleSubmit()
    }
  }

  // textCreated - create new assistant message
  const handleTextCreated = (content: Text) => {
    console.log('handleTextCreated', content)
    // add an empty message to start appending to
    addMessage('assistant', '')
  }

  // textDelta - append text to last assistant message
  const handleTextDelta = (delta: TextDelta, snapshot: Text) => {
    console.log(new Date().getTime(), 'handleTextDelta', delta, snapshot)
    if (delta.value != null) {
      appendToLastMessage(delta.value)
    } else if (snapshot.value != null) {
      replaceLastMessage(snapshot.value)
    }
  }

  // imageFileDone - show image in chat
  // TODO we don't have upload yet
  const handleImageFileDone = (image: ImageFile, snapshot: Message) => {
    console.log('handleImageFileDone', image, snapshot)
    appendToLastMessage(`\n![${image.file_id}](/api/files/${image.file_id})\n`)
  }

  // toolCallCreated - log new tool call
  const toolCallCreated = (toolCall: ToolCall) => {
    console.log('toolCallCreated', toolCall)
    // nothing to do
  }

  // toolCallDelta - log delta and snapshot for the tool call
  const toolCallDelta = (delta: ToolCallDelta, snapshot: ToolCall) => {
    // we don't need this, not rendering inline code interpreter responses
    console.log('toolCallDelta', delta, snapshot)
    if (delta.type != 'code_interpreter') return
    if (!delta.code_interpreter.input) return
    appendToLastMessage(delta.code_interpreter.input)
  }

  const functionCallHandler = async (
    toolCall: RequiredActionFunctionToolCall
  ) => {
    console.log('functionCallHandler', toolCall)
    return functions[toolCall.function.name](toolCall.function.arguments)
  }

  // handleRequiresAction - handle function call
  type ToolCallOutput = { output: string; tool_call_id: string }
  const handleRequiresAction = async (run: Run) => {
    console.log('handleRequiresAction', event)
    const thisRunId = run.id
    setWaiting(true)
    const toolCalls = run.required_action.submit_tool_outputs.tool_calls
    // loop over tool calls and call function handler
    const toolCallOutputs = await Promise.all(
      toolCalls.map(async (toolCall) => {
        const result = await functionCallHandler(toolCall)
        return { output: JSON.stringify(result), tool_call_id: toolCall.id }
      })
    )
    setSubmitDisabled(true)
    submitActionResult(thisRunId, toolCallOutputs)
  }

  // handleRunCompleted - re-enable the input form
  const handleRunCompleted = () => {
    setSubmitDisabled(false)
    setWaiting(false)
  }

  const handleReadableStream = (stream: AssistantStream) => {
    // messages
    stream.on('textCreated', handleTextCreated)
    stream.on('textDelta', handleTextDelta)

    // image
    stream.on('imageFileDone', handleImageFileDone)

    // code interpreter
    stream.on('toolCallCreated', toolCallCreated)
    stream.on('toolCallDelta', toolCallDelta)

    // events without helpers yet (e.g. requires_action and run.done)
    stream.on('event', (event) => {
      if (event.event === 'thread.run.requires_action') {
        handleRequiresAction(event.data)
      }
      if (event.event === 'thread.run.completed') {
        handleRunCompleted()
      }
    })
  }

  const appendToLastMessage = (text: string) => {
    console.log('appendToLastMessage', text)
    setMessages((prevMessages) => {
      const lastMessage = prevMessages[prevMessages.length - 1]
      const updatedLastMessage = {
        ...lastMessage,
        text: lastMessage.text + text,
      }
      return [...prevMessages.slice(0, -1), updatedLastMessage]
    })
  }

  const replaceLastMessage = (text: string) => {
    setMessages((prevMessages) => {
      const lastMessage = prevMessages[prevMessages.length - 1]
      const updatedLastMessage = {
        ...lastMessage,
        text: text,
      }
      return [...prevMessages.slice(0, -1), updatedLastMessage]
    })
  }

  const addMessage = (role: string, text: string, elementLabel?: string) => {
    console.log('adding message', role, text)
    setMessages((prevMessages) => [
      ...prevMessages,
      { role, text, elementLabel },
    ])
  }

  if (loading) {
    return <PanelLayout></PanelLayout>
  }

  return (
    <PanelLayout>
      <div className="my-2 flex-grow overflow-y-auto">
        {messages.length === 0 && (
          <div className="flex flex-col gap-4 px-4 py-1">
            <ChatMessage
              customRole={'assistant'}
              text="Hey there! I'm your web designer. Ask me to change your website or fix any issues!"
            />
          </div>
        )}
        {messages.map((message, index) => (
          <div className="flex flex-col gap-4 px-4 py-1" key={index}>
            <ChatMessage
              customRole={message.role}
              text={message.text}
              elementLabel={message.elementLabel}
            />
          </div>
        ))}
        {waiting && (
          <div className="flex flex-col gap-4 px-2 py-1">
            <ChatMessage
              customRole="assistant"
              waiting={true}
              text="Waiting for a response..."
              onCancelRun={cancelRun}
            />
          </div>
        )}
        {messages.length !== 0 && (
          <button
            className="mb-10 ml-4 flex flex-row text-sm text-gray-500"
            onClick={resetChat}
          >
            <ArrowCounterClockwise className="block h-5 w-5 pr-2" />
            Restart Chat
          </button>
        )}
        <div ref={messagesEndRef} />
      </div>

      <div className="focus-within:ring-pacific mx-2 rounded-xl shadow-sm focus-within:shadow-sm focus-within:ring-2">
        <div
          className={classNames({
            'relative rounded-t-xl px-2 py-1': true,
            'rounded-xl': !clickedElement,
            border: !clickedElement,
            'border-x border-t': clickedElement,
          })}
        >
          <textarea
            className="block min-h-full w-full resize-none scroll-p-5 overflow-x-auto rounded-t-xl px-2 py-2 leading-6 transition duration-100 placeholder:text-neutral-400 focus:outline-none"
            placeholder="What would you like to change..."
            rows={1}
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            onKeyDown={handleKeyDown}
          ></textarea>
          <button
            className="absolute right-2.5 top-1/2 m-0 h-4 w-4 -translate-y-1/2 disabled:opacity-50"
            disabled={submitDisabled}
          >
            <ArrowRight className="block h-4 w-4 align-middle" />
          </button>
        </div>
        {clickedElement && (
          <div className="flex items-center justify-between rounded-b-xl border border-emerald-200 bg-emerald-50 px-4 py-3">
            <div className="flex w-full items-center gap-3">
              <div className="relative mr-20 flex items-start rounded-xl border bg-gray-50 p-2">
                <div className="me-4">
                  <span className="flex items-center gap-2 pb-2 text-sm font-medium text-gray-900 ">
                    {truncate(getElementLabel(clickedElement.element))}
                  </span>
                  <button
                    className="absolute right-2 top-2 text-black"
                    onClick={handleClearClickedElement}
                  >
                    <X className="h-3 w-3" />
                  </button>
                  <span className="flex gap-1 text-xs font-normal text-gray-500">
                    <CrosshairSimple className="inline-block h-4 w-4" />
                    selected element
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </PanelLayout>
  )
})

const ChatMessage = ({
  customRole,
  text,
  elementLabel,
  waiting,
  onCancelRun,
}: {
  customRole: string
  text?: string
  elementLabel?: string
  waiting?: boolean
  onCancelRun?: () => void
}) => {
  const [markdown, setMarkdown] = useRemark()

  useEffect(() => {
    setMarkdown(text)
  }, [text])

  if (customRole === 'user') {
    return (
      <div className="leading-1.5 prose ml-5 flex flex-col whitespace-pre-wrap rounded-s-xl rounded-ee-xl border-gray-200 bg-blue-100 px-4 py-2.5 text-sm text-gray-900">
        {markdown}
        {elementLabel && (
          <div className="my-2.5 flex items-start rounded-xl bg-gray-50 p-2">
            <div className="me-2">
              <span className="flex items-center gap-2 pb-2 text-sm font-medium text-gray-900">
                {truncate(elementLabel)}
              </span>
              <span className="flex gap-1 text-xs font-normal text-gray-500">
                <CrosshairSimple className="inline-block h-4 w-4" />
                selected element
              </span>
            </div>
          </div>
        )}
      </div>
    )
  }

  if (customRole === 'assistant') {
    return (
      <div className="leading-1.5 prose mr-5 flex flex-col whitespace-pre-wrap rounded-e-xl rounded-es-xl border-gray-200 bg-gray-100 px-4 py-2.5 text-sm text-gray-900">
        {waiting && (
          <button className="mr-1 inline-block" onClick={onCancelRun}>
            <CircleNotch className="block h-4 w-4 animate-spin" />
          </button>
        )}
        {markdown}
      </div>
    )
  }
}

const PanelLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="shrink basis-1/4 overflow-hidden p-4">
      <div className="flex h-full gap-4">
        <div className="shrink grow">
          <div className="relative flex h-full min-w-0 flex-col rounded bg-white pb-4 shadow-md">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChatPanel

// overriding truncate from 'src/lib/formatters' to make it shorter
const truncate = (value: string) => {
  const max = 75
  let output = value?.toString() ?? ''

  if (output.length > max) {
    output = output.substring(0, max) + '...'
  }

  return output
}

const getElementLabel = (element: HTMLElement) => {
  return element?.innerText || element?.tagName
}
